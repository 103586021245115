<template>
  <div class="content">
    <div class="tilte">
      <div></div>
      <span>{{ $t('menu.about[3]') }}</span>
    </div>
    <div class="leaveAMessage">
      <textarea v-model="textarea" :placeholder="$t('menu.megluyan')"></textarea>
      <div class="addbtn" @click="feedback">{{ $t('button.tijiao') }}</div>
    </div>
  </div>
</template>

<script>
  import {feedback} from '@/api/main.js'
export default {
  data() {
    return {
      textarea: ''
    }
  },
  mounted() {
  },
  methods: {
    feedback(){
      if(!this.textarea){
        if (this.isChinese()) {
            this.$message({
                message: '请输入留言内容',
                type: 'warning'
            });
          }else{
            this.$message({
                message: 'Please enter the message content',
                type: 'warning'
            });
          }
          return
      }

      feedback({
        content:this.textarea
      }).then(res=>{
        if(res.code==0){
          if (this.isChinese()) {
            this.$message({
                message: '提交成功',
                type: 'success'
            });
          }else{
            this.$message({
                message: 'Submit successfully',
                type: 'success'
            });
          }
          this.textarea = ''
        }else{
            this.showMessage();
        }
      })
    }
  }
}
</script>
<style lang="scss">
.content {
  width: 880px;

  .tilte {

    display: flex;
    align-items: center;
    padding: 9px 0px;
    padding-top: 0px;

    div {
      width: 2px;
      height: 24px;
      background: #333333;
      margin-right: 10px;
    }

    span {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }

  .leaveAMessage {
    margin-top: 20px;
    width: 880px;
    border: 1px solid #DDDDDD;
    padding: 20px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    letter-spacing: 2px;
    line-height: 25px;

    textarea {
      height: 200px;
      width: 100%;
      resize: none;
      border: none;
    }

    .addbtn {
      margin-right: 20px;
      margin-top: 20px;
      width: 148px;
      border: 1.5px solid transparent;
      border-radius: 0;
      white-space: nowrap;
      transition: all 0.3s ease;
      border: 2px solid #000;
      text-align: center;
      padding: 10px 0px;

      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
    }

    .addbtn:hover {
      background: #000;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>